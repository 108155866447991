<template>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card title="CKPN Net Flow / Roll Rates">
          <div class="row mt-2">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
              <span class="mt-1 float-right">Tahun</span>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <input type="number" v-model="tahun" class="ant-input" style="width: 200px;" placeholder="Ketik tahun" id="yearPicker" min="1900" max="2100" />
                <!-- <a-button class="btn btn-outline-success ml-2" @click="req">Download Hasil Perhitungan</a-button> -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
              <span class="mt-1 float-right">Cabang</span>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                <a-select
                  v-model="cabang"
                  show-search
                  placeholder="Pilih Kode Cabang"
                  option-filter-prop="children"
                  style="width: 70px;"
                  :filter-option="filterOption"
                >
                  <a-select-option value="all">
                    All
                  </a-select-option>
                  <a-select-option v-for="(data, index) in dataCabangList" :key="index" :value="data.kode">
                    {{ data.kode }}
                  </a-select-option>
                </a-select>
                <a-button class="btn btn-outline-success ml-2" @click="req">Download Hasil Perhitungan</a-button>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
          </div>
        </a-card>
      </div>
    </div>
  </template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created() {
    this.cabang = localStorage.getItem('defaultsandikc') || 'all'
    this.dataCabangList = this.user.cabangList
  },
  data() {
    return {
      cabang: 'all',
      dataCabangList: [],
      tahun: moment().subtract(1, 'years').year(),
    }
  },
  methods: {
    moment,
    // handlePanelChange(value, mode) {
    //   if (mode === 'year') {
    //     this.tahun = value.year()
    //   }
    // },
    async req() {
      var imbuhan = ''
      imbuhan += this.tahun !== null && this.tahun !== '' ? imbuhan !== '' ? '&tahun=' + this.tahun : '?tahun=' + this.tahun : ''
      imbuhan += this.cabang !== null && this.cabang !== '' ? imbuhan !== '' ? '&cabang=' + this.cabang : '?cabang=' + this.cabang : ''
      var res = await lou.getDocumentExcel('ckpn/netflow-rollrates' + imbuhan)
      if (res) {
        lou.shownotif('File berhasil didownload!')
      } else {
        lou.shownotif('Silahkan coba lagi!. dalam beberapa saat!')
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

  <style>

  </style>
